import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'

function Confirm({ formData, handleForward, handleBack }) {
    const handleKeyDown = (event) => {
        if (event.key === 'Backspace') {
            handleBack()
            event.preventDefault()
        }
    }

    return (
        <Modal.Dialog backdrop='static' keyboard={0}>
            <Modal.Header>
                <Modal.Title>El Cheff - Emergencias</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Por favor confirme sus datos, sobre todo número de teléfono.</p>
                <Table striped bordered hover size="sm">
                    <tbody>
                        <tr>
                            <td>Nombre comercial</td>
                            <td>{formData.comercio}</td>
                        </tr>
                        {formData.sucursal ?
                            <tr>
                                <td>Sucursal</td>
                                <td>{formData.sucursal}</td>
                            </tr>
                            : null
                        }
                        <tr>
                            <td>Número telefónico</td>
                            <td>{formData.telefono}</td>
                        </tr>
                        <tr>
                            <td>Nombre de quien reporta</td>
                            <td>{formData.nombre}</td>
                        </tr>
                        {formData.descripcion ?
                            <tr>
                                <td>Descripción del problema</td>
                                <td>{formData.descripcion}</td>
                            </tr>
                            : null
                        }
                    </tbody>
                </Table>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary outline" onClick={handleBack}>Corregir</Button>
                <Button
                    variant="primary"
                    className='btn-default'
                    autoFocus
                    onClick={handleForward}
                    onKeyDown={handleKeyDown}
                >Enviar</Button>
            </Modal.Footer>

        </Modal.Dialog>
    )
}

export default Confirm