import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

function Conditions({ handleClose }) {
    return (
        <Modal.Dialog backdrop='static' keyboard={0}>
            <Modal.Header>
                <Modal.Title>El Cheff - Emergencias</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Esta página es para uso exclusivo de clientes que hayan contratado nuestra póliza de servicio</p>
                <p>Si su póliza de servicio es de segundo nivel solo responderemos al contacto registrado como personal de sistemas</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={handleClose} className='btn-default' autoFocus>Acepto las condiciones</Button>
            </Modal.Footer>
        </Modal.Dialog>
    )
}

export default Conditions