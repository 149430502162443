import React from 'react'
import styled from 'styled-components'

import { Formik, useField } from 'formik';
import * as Yup from 'yup';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/FloatingLabel'


function MessageForm({ initialValues, onSubmit }) {
    return (
        <Row className='mt-3' >
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                    comercio: Yup.string()
                        .required('Requerido')
                        .max(80, 'Máximo 80 caracteres'),
                    sucursal: Yup.string()
                        .max(80, 'Máximo 80 caracteres'),
                    telefono: Yup.string()
                        .required('Requerido')
                        .min(8, "Mínimo 8 dígitos")
                        .max(15, "Mínimo 15 dígitos")
                        .matches(/^\+?\d{7,}$/, 'Solo dígitos o +digitos'),
                    nombre: Yup.string()
                        .required('Requerido')
                        .max(80, 'Máximo 80 caracteres'),
                    descripcion: Yup.string()
                        .max(80, 'Máximo 80 caracteres')
                })}
                onSubmit={(values, { setSubmitting }) => {
                    onSubmit(values)
                    setSubmitting(false)
                }}
            >
                {({
                    handleSubmit,
                    isSubmitting,
                    isValid,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <AuthField
                            label='Nombre comercial'
                            type='text'
                            name='comercio'
                            placeholder='comercio'
                            autoFocus
                        />
                        <AuthField
                            label='Sucursal (si hay más de una)'
                            type='text'
                            name='sucursal'
                            placeholder='sucursal'
                        />
                        <AuthField
                            label='Número telefónico'
                            type='text'
                            name='telefono'
                            placeholder='telefono'
                            onChange={(e) => {
                                e.target.value = e.target.value
                                    .replace(/[^0-9+]/g, '')
                                    .replace(/(.)\+/g, '$1')
                            }}
                        />
                        <AuthField
                            label='Nombre de quien reporta'
                            type='text'
                            name='nombre'
                            placeholder='nombre'
                        />
                        <AuthField
                            label='Descripción del problema (opcional)'
                            type='text'
                            name='descripcion'
                            placeholder='descripcion'
                        />

                        <Col className='d-flex justify-content-end mb-3'>
                            <Button variant="primary" type="submit" disabled={isSubmitting || !isValid} className='d-flex justify-self-right'>
                                Continuar
                            </Button>
                        </Col>
                    </Form>
                )}
            </Formik>
        </Row>
    )
}

const AuthFeedback = styled(Form.Control.Feedback)`
    font-size: 0.75rem;
`

const AuthField = ({ label, ...props }) => {
    const [field, meta] = useField(props);

    const handleChange = (e) => {
        props.onChange(e);
        field.onChange(e);
    }
    const onChange = props.onChange ? handleChange : field.onChange;

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            const field = event.target
            const inputs = Array.from(document.getElementsByTagName('input'))
            const index = inputs.findIndex(input => input === field)

            if (index < inputs.length - 1) {
                inputs[index + 1].focus()
                inputs[index + 1].select()
                event.preventDefault()
                return false
            }
        }
    }

    return (
        <>
            <FloatingLabel
                controlId={`floating${field}`}
                label={label}
                className="mb-3"
            >
                <Form.Control
                    {...field}
                    {...props}
                    onChange={onChange}
                    isInvalid={meta.touched && meta.error}
                    onKeyPress={handleKeyPress}
                />
                <AuthFeedback type="invalid">
                    {meta.error}
                </AuthFeedback>
            </FloatingLabel>
        </>
    )
};



export default MessageForm
