import React, { useState, useEffect } from 'react'
import { API, Logger } from 'aws-amplify'
import styled from 'styled-components'
import * as queryString from 'query-string'

import img from './img/background-3.jpg'
import cheff from './img/cheff.svg'
import sos from './img/sos.svg'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Spinner from 'react-bootstrap/Spinner'

import MessageForm from './MessageForm.js'
import Conditions from './Conditions.js'
import Done from './Done.js'
import Confirm from './Confirm.js'
import BusinessHours from './BusinessHours.js'

const logger = new Logger('App')

const Content = styled.div`
  border: 1px solid #000;
  background-image: url(${img});
  background-repeat: repeat-y;
  --header-height: 200px;
  background-position: 0 var(--header-height);
  min-height: 700px;
`

const Header = styled(Container)`
  max-height: var(--header-height);
`

const HeaderCol = styled(Col)`
  background-color: #040505;
  max-height: var(--header-height);
  display: flex;
  justify-content: center;
`

const HeaderTitle = styled.h1`
  text-align: center;
  padding: 0.5em;
`

const Loading = styled(Spinner)`
  width: 100px;
  height: 100px;
`

const Freepik = styled.div`
  font-size:0.6em;
  text-align: right;
`

const states = {
  CONDITIONS: "conditions",
  FORM: "form",
  CONFIRM: "confirm",
  SENDING: "sending",
  DONE: "done",
}

let initialState = { comercio: '', sucursal: '', telefono: '', nombre: '', descripcion: '' }
const query = queryString.parse(window.location.search);
if (query.serie || query.usuario || query.comercio) {
  if (query.serie)
    initialState.serie = query.serie
  if (query.usuario) {
    const usuario = Buffer.from(query.usuario, 'base64').toString()
    initialState.usuario = usuario
    initialState.nombre = usuario
  }
  if (query.comercio) {
    const comercio = Buffer.from(query.comercio, 'base64').toString()
    initialState.nombreSentinela = comercio
    initialState.comercio = comercio
  }
}
if (query.q) {
  const params = JSON.parse(Buffer.from(query.q, 'base64').toString())
  initialState = { ...initialState, ...params }
}


function App() {
  const [showDialog, setShowDialog] = useState(states.CONDITIONS)
  const [formData, setFormData] = useState(initialState)
  const [currentTime, setCurrentTime] = useState({ loaded: false })
  const [ok, setOk] = useState(undefined);



  useEffect(() => {
    fetchTime()
  }, [])

  const fetchTime = async () => {
    if (!query.q) {
      API
        .get('smsapi', '/time')
        .then(response => {
          setCurrentTime({ ...response, loaded: true })
        })
        .catch(error => {
          logger.error('fetchTime', error)
          setCurrentTime({ business_hours: false, loaded: true })
        })
    } else {
      setCurrentTime({ business_hours: false, loaded: true })
      setShowDialog(states.CONFIRM)
    }
  }

  const sendMessage = async () => {
    logger.info('SENDING', formData)
    setShowDialog(states.SENDING);

    if (!query.debug) {
      API
        .post('smsapi', '/emergencia', { body: formData })
        .then(response => {
          logger.info('RESPONSE', response)
          console.log(`***EMERGENCIAS*** ${response.ok ? 'OK' : 'ERROR'}:  ${JSON.stringify(formData)}`)

          setOk(response.ok === true)
          setShowDialog(states.DONE)
        })
        .catch(error => {
          logger.error('sendMessage', error)
          console.log(`***EMERGENCIAS*** ERROR: ${JSON.stringify(formData)} ${error}`)

          setOk(false)
          setShowDialog(states.DONE)
        })
    } else {
      window.setTimeout(() => {
        console.log(`***EMERGENCIAS*** DEBUG: ${JSON.stringify(formData)}`)

        setOk(true)
        setShowDialog(states.DONE)
      }, 3000)
    }
  }

  return (<>
    <Content>
      <Header fluid>
        <Row>
          <HeaderCol>
            <Image src={cheff} alt="Logo" />
          </HeaderCol>
          <HeaderCol>
            <Image src={sos} alt="SOS" />
          </HeaderCol>
        </Row>
      </Header>
      <Container>
        {showDialog === states.CONDITIONS && <Conditions handleClose={() => setShowDialog(states.FORM)} />}
        {((showDialog === states.FORM && !currentTime.loaded) || (showDialog === states.SENDING)) &&
          <Row><Col className='d-flex justify-content-center mt-5'>
            <Loading animation="border" role="status" />
          </Col></Row>
        }
        {showDialog === states.FORM && currentTime.loaded && currentTime.business_hours &&
          <BusinessHours
            currentTime={currentTime}
            handleForward={() => setCurrentTime({ ...currentTime, business_hours: false })}
          />
        }
        {showDialog === states.FORM && currentTime.loaded && !currentTime.business_hours &&
          <>
            <Row>
              <Col>
                <HeaderTitle>El Cheff - Emergencias</HeaderTitle>
              </Col>
            </Row>
            <MessageForm initialValues={formData} onSubmit={(formData) => {
              logger.info('DIALOG', formData)
              setFormData(formData)
              setShowDialog(states.CONFIRM)
            }} />
          </>
        }
        {showDialog === states.CONFIRM &&
          <Confirm
            formData={formData}
            handleForward={sendMessage}
            handleBack={() => setShowDialog(states.FORM)}
          />
        }
        {showDialog === states.DONE && <Done ok={ok} embedded={query.embedded} />}
      </Container>
    </Content>
    <Freepik>
      Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a>{' '}
      and <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a>{' '}
      from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
    </Freepik>
  </>
  );
}

export default App;
