import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

function BusinessHours({ currentTime, handleForward }) {
    const days = ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo']

    const right = (s, n) => s.slice(s.length - n, s.length)
    const format = (n) => right("0" + n, 2)

    return (
        <Modal.Dialog backdrop='static' keyboard={0}>
            <Modal.Header>
                <Modal.Title>El Cheff - Emergencias</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>En días y horas hábiles (lunes a viernes de 09:30 a 18:30) recibirá una respuesta más rápida marcando al <b>{currentTime.phone_number}</b>.</p>
                <p>
                    En {days[currentTime.weekday]} a las {format(currentTime.hour)}:{format(currentTime.minute)} tenemos técnicos
                    disponibles esperando su llamada.
                </p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={handleForward} className='btn-default' autoFocus>Prefiero enviar mensaje</Button>
            </Modal.Footer>
        </Modal.Dialog>
    )
}

export default BusinessHours