import React from 'react'

import styled from 'styled-components'

import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'

import check from './img/check.svg'
import remove from './img/remove.svg'


const SmallImage = styled(Image)`
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: top;
    margin-right: 0.35em;
`


function Done({ ok, embedded }) {

    return (
        <Modal.Dialog backdrop='static' keyboard={0}>
            <Modal.Header>
                <Modal.Title>El Cheff - Emergencias</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {ok &&
                    <Alert variant="success">
                        <Alert.Heading><SmallImage src={check} alt="Exito" />Su mensaje se envió con éxito</Alert.Heading>
                        <p>Recibimos su mensaje y nos comunicaremos a la brevedad.</p>
                    </Alert>
                }
                {!ok &&
                    <Alert variant="danger">
                        <Alert.Heading><SmallImage src={remove} alt="Error" /> Su mensaje no se pudo enviar</Alert.Heading>
                        <div>
                            <p>Por favor pruebe alguno de los siguientes medios alternativos de contacto.</p>

                            Envie un SMS al (55)9035-4606 desde México o +1(878)999-8502 desde resto del mundo.
                            <ul>
                                <li>Estos números solo aceptan SMS. No marque ni envie whatsapp.</li>
                                <li>El "+1" es indispensable. Si no lo incluye no recibiremos su mensaje.</li>
                            </ul>

                            No olvide incluir nombre comercial (con sucursal), teléfono para que nos reportemos y nombre de quien reporta.
                        </div>
                    </Alert>
                }
            </Modal.Body>
            <Modal.Footer>
                {!embedded && <div>Ya puede cerrar la ventana</div>}
                {embedded &&
                    <Button
                        variant="primary"
                        autoFocus
                        onClick={() => console.log("***EMERGENCIAS*** CLOSE")}
                    >Terminar</Button>
                }
            </Modal.Footer>
        </Modal.Dialog>
    )
}

export default Done